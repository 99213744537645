.pgwSlider {
  width: 100%;
  color: #fff;
}

.pgwSlider a {
  color: #fff;
  text-decoration: none;
  display: block;
}

.pgwSlider .ps-current {
  float: left;
  width: 74%;
  height: inherit;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
}

.pgwSlider.listOnTheLeft .ps-current {
  float: right;
}

.pgwSlider .ps-current ul {
  width: 100%;
}

.pgwSlider .ps-current li {
  width: 100%;
  z-index: 1;
  opacity: 0;
  display: none;
}

.pgwSlider .ps-current img {
  max-width: 100%;
  min-width: 100%;
  height: auto;
  transition: margin-top .25s linear;
  display: block;
}

.pgwSlider .ps-caption {
  width: 100%;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= #9000, endColorstr= #9000);
  z-index: 3;
  background: #000000b3;
  padding: 12px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

ul.pgwSlider, .pgwSlider ul {
  float: right;
  width: 25%;
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.pgwSlider.listOnTheLeft, .pgwSlider.listOnTheLeft > ul {
  float: left;
}

ul.pgwSlider > li, .pgwSlider > .ps-list > li {
  height: 50px;
  opacity: .6;
  filter: alpha(opacity= 60);
  margin-bottom: 6px;
  font-size: .8rem;
  transition: opacity .2s linear;
  position: relative;
  overflow: hidden;
}

ul.pgwSlider > li:last-child, .pgwSlider > ul > li:last-child {
  margin-bottom: 0;
}

ul.pgwSlider > li span, .pgwSlider > .ps-list > li span {
  width: 100%;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= #9000, endColorstr= #9000);
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #000000b3;
  padding: 5px;
  display: block;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

ul.pgwSlider > li:hover, .pgwSlider > ul > li:hover {
  opacity: 1 !important;
}

ul.pgwSlider > li img, .pgwSlider > ul > li img {
  width: 100%;
  min-height: 100%;
  transition: margin-top .25s linear;
  display: block;
}

.pgwSlider .ps-prevIcon {
  border: 10px solid #0000;
  border-left: 0 solid #fff;
  border-right-color: #fff;
  display: block;
}

.pgwSlider .ps-nextIcon {
  border: 10px solid #0000;
  border-left-color: #fff;
  border-right: 0 solid #fff;
  display: block;
}

.pgwSlider .ps-current .ps-prev {
  filter: progid:DXImageTransform. Microsoft. gradient(GradientType= 0, startColorstr= "#99000000", endColorstr= "#99000000");
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#99000000', endColorstr='#99000000')";
  cursor: pointer;
  background: #00000080;
  border: 1px solid #777;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  padding: 20px 20px 20px 17px;
  position: absolute;
  top: 45%;
  left: 0;
}

.pgwSlider .ps-current .ps-next {
  filter: progid:DXImageTransform. Microsoft. gradient(GradientType= 0, startColorstr= "#99000000", endColorstr= "#99000000");
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#99000000', endColorstr='#99000000')";
  cursor: pointer;
  background: #00000080;
  border: 1px solid #777;
  border-right: 0;
  border-radius: 4px 0 0 4px;
  padding: 20px 17px 20px 20px;
  position: absolute;
  top: 45%;
  right: 0;
}

ul.pgwSlider.wide > li, .pgwSlider.wide > ul > li {
  width: 100% !important;
}

.pgwSlider.narrow .ps-current {
  margin-bottom: 6px;
  font-size: .8rem;
}

.pgwSlider.narrow .ps-current img {
  width: 100%;
  min-height: inherit;
}

.pgwSlider.narrow .ps-current, ul.pgwSlider.narrow, .pgwSlider.narrow > ul {
  width: 100%;
}

ul.pgwSlider.narrow > li, .pgwSlider.narrow > .ps-list > li {
  float: left;
  min-height: 50px;
  max-height: 70px;
  min-width: 15%;
  margin-bottom: 0;
  font-size: .7rem;
}

ul.pgwSlider.narrow.listOnTheLeft > li, .pgwSlider.narrow.listOnTheLeft > ul > li {
  float: right;
}

.pgwSlider.narrow .ps-caption {
  padding: 8px;
}

.pgwSlider.narrow .ps-current .ps-prev {
  padding: 15px 15px 15px 12px;
  top: 40%;
}

.pgwSlider.narrow .ps-current .ps-next {
  padding: 15px 12px 15px 15px;
  top: 40%;
}

/*# sourceMappingURL=index.82eee780.css.map */
